/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
//  import firebase from 'firebase/app';
//  import 'firebase/auth';
 import config from '../config/config'
 import { resetWidgetData, fetchUserOrganizations, getAllGroupMembersFromWidget} from '../actions'
 import { NotificationManager } from 'react-notifications';
 import {userProfileAPI, axiosClient, lifesherpaAuthBaseAPI, axiosAuthClient} from '../sherpaApi'
 import {getFakeProfile, checkEnterpriseConnection} from '../helpers/helpers'
 import {auth, database} from '../firebase'
 import { ref, onValue  } from "firebase/database"
 import {signInWithCustomToken, signOut, createUserWithEmailAndPassword} from 'firebase/auth'
 import {registerDeviceForNotification} from './ContactsAction'

 import {
   LOGIN_USER,
   LOGIN_USER_SUCCESS,
   LOGIN_USER_FAILURE,
   LOGOUT_USER,
   LOGOUT_USER_SUCCESS,
   GET_STATUS,
   SIGNUP_USER,
   SIGNUP_USER_SUCCESS,
   SIGNUP_USER_FAILURE,
   GET_USER_PROFILE,
   GET_USER_BY_ID,
   GET_SUCCESS_USER_BY_ID,
   SET_USER_DETAIL,
   RESET_USER_PASSWORD,
   RESET_USER__PASSWORD_SUCCESS,
   POST_LIFE_SHERPA_CREATE_USER,
   POST_LIFE_SHERPA_SUCCESS_CREATE_USER,
   GET_VALIDATE_INVITE_CODE,
   GET_SUCCESS_VALIDATE_CODE,
   SHOW_FIREBASE_AUTH_LOADING,
   HIDE_FIREBASE_AUTH_LOADING,
   VERIFY_USER_ACCOUNT_VIA_MAIL,
   VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL,
   RESEND_VERIFICATION_LINK,
   RESEND_VERIFICATION_LINK_SUCCESS,
   GET_ORGANIZATION_PRIVACY_POLICY,
   GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY,
   POST_LIFE_SHERPA_LOGIN,
   POST_LIFE_SHERPA_LOGIN_SUCCESS,
   GET_USER_FIREBASE_TOKEN,
   GET_SUCCESS_USER_FIREBASE_TOKEN,
   GET_USER_ORGANIZATION,
   GET_SUCCESS_USER_ORGANIZATION,
   GET_USER_ORGANIZATION_FROM_TOKEN,
   GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN,
   UPDATE_USER_STATUS,
   SET_STATUS_COLOR,
   GET_STATUS_SECTIONS,REMOVE_STATUS,
   GET_LMS_URL,
   GET_LMS_URL_SUCCESS,
   GET_LMS_URL_FAILED,
   SET_LOGOUT_LMS_URL,
   GET_ALLOWED_MODULE,
   GET_ALLOWED_MODULE_SUCCESS,
   USER_LOGGED_OUT_RESET_REDUX,
   GET_SUPER_ADMIN_DATA,
   GET_SUPER_ADMIN_DATA_SUCCESS,
   GET_GLOBAL_ADMIN_DATA,
   GET_GLOBAL_ADMIN_DATA_SUCCESS,
   GET_CLIENT_STATUS_DATA,
   GET_STATUS_LOADING,
   SET_FORM_STATUS_DATA,
   HIDE_STATUS_LOADING,
   SHOW_UPDATE_PASSWORD_LOADING,
   HIDE_UPDATE_PASSWORD_LOADING,
   SHOW_PASSWORD_UPDATE_DIALOG,
   PASSWORD_UPDATED_SUCCESSFULLY,
   PASSWORD_UPDATE_UNSUCCESSFULL,
   CLEAR_UPDATE_PASSWORD_ERROR,
   CLEAR_PUBLIC_SETTING,
   POST_LIFE_SHERPA_FAILED_CREATE_USER,
   HIDE_CREATE_USER_FAILED_DATA,
   CLEAR_GET_VALIDATE_INVITE_CODE_RESPONSE,
   GET_FAILED_USER_FIREBASE_TOKEN,
   CHANGE_THEME_COLOR,
   GET_THEME_LS_LOGO,
   GET_THEME_LS_LOGO_SUCCESS,
   GET_THEME_LS_LOGO_FAILED,
   SET_DYNAMIC_THEME_CLASS,
   OPENED_CONFIGURATOR_IN_LSPORTAL,
   OPENED_LMS_IN_LSPORTAL,
   ENABLE_DFAULT_AUTH0_LOGIN_STATUS,
   DISABLE_DFAULT_AUTH0_LOGIN_STATUS,
   SET_USA_AUTH0_ENABLED,
   SET_AUSTRALIA_AUTH0_ENABLED,
   GET_WORKSPACE_IS_AVAILABLE,
   SHOW_ANALYTICS_MENU,
   HIDE_ANALYTICS_MENU,
   GET_WORKSPACE_ADMIN_DATA,
   GET_WORKSPACE_ADMIN_DATA_SUCCESS,
   ANALYTICS_LOGIN,
   SHOW_PUSH_NOTIFICATION_ALERT,
   HIDE_PUSH_NOTIFICATION_ALERT,
   GET_USER_INFO,
   GET_USER_BY_ID_FAILED,
   SET_CURRENT_USER_TIMEZONE,
   SET_SUCCESS_CURRENT_USER_TIMEZONE,
   SHOW_NOTIFICATION_LOADING,
   HIDE_NOTIFICATION_LOADING,
   SAVE_USER_HAVING_NO_GROUP,
   HIDE_THEME_LOADING,
   FETCH_PRIVACY_POLICIES,
   FETCH_PRIVACY_POLICIES_SUCCESS,
   SAVED_PRIVACY_POLICIES,
   SAVED_PRIVACY_POLICIES_SUCCESS,
   SAVED_PRIVACY_POLICIES_FAILED,
   GET_ALLOW_CHANGE_PASSWORD
 } from './types';
import moment from 'moment'
import { configureStore } from '../store';
import { func } from 'joi';

export const setUserTimezone = (userId, idToken) => (dispatch) => {
   const url = `/users/${userId}/timezone`;
   const offset = moment().format('Z')
   const name = Intl.DateTimeFormat().resolvedOptions().timeZone
   const data = {name, offset}
   console.log(" Setting user timezone for userId:",userId,".....", data);
   dispatch({ type: SET_CURRENT_USER_TIMEZONE });
   axiosClient.post(url, data, {
      headers: {
         'Authorization': `Bearer ${idToken}`
      }
      }).then((response) => {
         console.log("%c Fetched user details for userId:"+userId,"color:green");
         dispatch({ type: SET_SUCCESS_CURRENT_USER_TIMEZONE, payload: response.data});
       
      }).catch((error) => {
         // error handling
         dispatch({type:GET_USER_BY_ID_FAILED})
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         console.log("%c Error Occured while fetching user details by userId","color:red", errorMsg, error);
         //console.log("error occured ", error);
      })
}

 export const setUserDetail = (userDetail) => (dispatch) => {
    dispatch({type: SET_USER_DETAIL, payload: userDetail})
 }
 //get LMS Auth URL
 export const setLmsLogoutUrl = (url) => (dispatch) => {
   dispatch({type:SET_LOGOUT_LMS_URL, payload: url})
}
 export const getLmsLoginUrl = () => (dispatch) => {
   const API_ENDPOINT = 'AIzaSyCD-Tq45VrpVcNRbmIe__AmHVg_lS77VGQ';
    let loginurl = `/auth/v3/talentlms/course/lms-login-url`;
      let userId = localStorage.getItem("uid");
      let orgId = localStorage.getItem("orgId");
      let data =  {userId:userId, orgId: orgId};
      console.log(" Fetching LMS Login URL for userId"+userId+" .....");
     dispatch({ type: GET_LMS_URL});
     axiosAuthClient.post(loginurl, data)
     .then((response) => {
        if (response.status === 200) {
         console.log("%c fetched LMS Login URL for userId:"+userId,"color:green");
         dispatch({ type: GET_LMS_URL_SUCCESS, payload: response.data.url});
             
        } else {
           console.log("%c Error Occured while fetching LMS Logout URL","color:red");
        }
     }).catch((error) => {
      dispatch({ type: GET_LMS_URL_FAILED});
      const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
      console.log("%c Error Occured while fetching LMS Logout URL","color:red", errorMsg);
     })
   
   }
 //get user by id

export const getUserById = (userId, idToken, widgetApp, initRequest = true) => (dispatch) => {
   const orgId = localStorage.getItem("selectedOrgId")
   let url = `/users/${userId}`;
   console.log(" Fetching user details for userId:",userId,".....");
   dispatch({ type: GET_USER_BY_ID });
   dispatch({type: HIDE_NOTIFICATION_LOADING});
   axiosClient.get(url, {
      headers: {
         'Authorization': `Bearer ${idToken}`
      }
      }) 
      .then((response) => {
         console.log("%c Fetched user details for userId:"+userId,"color:green");
         dispatch({ type: GET_SUCCESS_USER_BY_ID, payload: response.data});

         if(response.status === 200) {
            localStorage.setItem("organization", response.data.organization);
            localStorage.setItem("selectedOrgId", response.data.organization);
            localStorage.setItem("orgId", response.data.organization);
            localStorage.setItem("pokeFormId", response.data.pokeFormId);
            console.log("App widget ->", widgetApp || "LifeSherpa")
            const user = response.data || {};
            if(widgetApp) {
               user.uid = userId;
               user.name = user.displayName || ((user.firstName || "Display") && (user.lastName || "Name"));
               user.picture = user.profileImageURL;
               dispatch({type:GET_USER_INFO, payload: user});
               dispatch({type: FETCH_PRIVACY_POLICIES_SUCCESS, payload: {status: true}});
               if (response.data.role === 'Parent') {
                  dispatch(getAllGroupMembersFromWidget())
               }
               return;
            } else if (response.data.role === 'Parent') {
               if (response.data.selectedGroupId) {
                  // localStorage.setItem("selectedGroup", response.data.selectedGroupId)
               }
            }
            if(initRequest) {
               dispatch(fetchUserOrganizations(user))
               dispatch(getAuthorisedModule(userId, response.data.organization))
               dispatch(isSuperAdmin(userId))
               dispatch(isGlobalAdmin(userId))
            }
         } 
      
      })
      .catch((error) => {
         // error handling
         dispatch({type:GET_USER_BY_ID_FAILED})
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         console.log("%c Error Occured while fetching user details by userId","color:red", errorMsg, error);
         //console.log("error occured ", error);
      })
 }

 //get user by id
 export const getUserByIdConfigurator = (userId, idToken) => (dispatch) => {
   const orgId = localStorage.getItem("orgId")
   let url = `/users/${userId}?organization=${orgId}`
   dispatch({ type: GET_USER_BY_ID });
   axiosClient.get(url, {
      headers: {
         'Authorization': `Bearer ${idToken}`
      }
      }).then((response) => {

         dispatch(getUserProfileConfigurator(response.data, idToken))
         dispatch({ type: GET_SUCCESS_USER_BY_ID, payload: response.data});
         localStorage.setItem("organization", response.data.organization);
         localStorage.setItem("pokeFormId", response.data.pokeFormId)

       }).catch((error) => {
         // error handling
         console.log("error occured ", error);
     })
}

export const getUserProfileConfigurator = (user, idToken) => (dispatch) => {
   dispatch({ type: GET_USER_PROFILE });
   userProfileAPI.get(`${user.email}.json`)
       .then((response) => {
          const responseData = response.data.entry[0]
         dispatch({ type: LOGIN_USER_SUCCESS, payload: responseData});
       })
       .catch(async(error) => {
         // error handling
         const userData = user && user.email ? getFakeProfile(user.email) : null
         dispatch({ type: LOGIN_USER_SUCCESS, payload: userData});
     })
}
export const getUserThemeById = (userId, idToken,history) => (dispatch) => {
   const orgId = localStorage.getItem("selectedOrgId")
   console.log(" Fetching user details by userId........");
   let url = `/users/${userId}`
   dispatch({ type: GET_USER_BY_ID });
   axiosClient.get(url, {
      headers: {
         'Authorization': `Bearer ${idToken}`
      }
      }) 
       .then((response) => {
         dispatch(getWorkspaceDetails(response.data.organization));
         console.log("%c Fetched user details for userId:"+userId,"color:green");
         dispatch(setDefaultThemeForUser(response.data.theme,history));
         dispatch(getAuthorisedModule(userId, response.data.organization))
         dispatch(isSuperAdmin(userId))
         dispatch(isGlobalAdmin(userId))
         dispatch({ type: GET_SUCCESS_USER_BY_ID, payload: response.data});
         localStorage.setItem("organization", response.data.organization);
         localStorage.setItem("selectedOrgId", response.data.organization);
         localStorage.setItem("orgId", response.data.organization);
         localStorage.setItem("pokeFormId", response.data.pokeFormId);
       })
       .catch((error) => {
           // error handling
           const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
           console.log("%c Error Occured while fetching user details by userId","color:red", errorMsg);
          // console.log("error occured ", error);
       })
}



 /**
  * Redux Action To Get User Profile Details
  */
 
 export const getUserProfile = (user, history, idToken) => (dispatch) => {
   console.log(" Fetching user profile data........");
    dispatch({ type: GET_USER_PROFILE });
    userProfileAPI.get(`${user.email}.json`)
      .then((response) => {
         console.log("%c Fetched user profile data ","color:green");
         dispatch({ type: LOGIN_USER_SUCCESS, payload: response.data.entry[0]});
         dispatch(getUserById(user.uid, idToken))
         dispatch(getUserStatusById(user.uid, idToken))
         dispatch(getUserThemeById(user.uid, idToken,history))
         // NotificationManager.success('User logged in successfully!');
      })
      .catch((error) => {
         // error handling
         const userData = user && user.email ? getFakeProfile(user.email) : null
         dispatch({ type: LOGIN_USER_SUCCESS, payload: userData});
         dispatch(getUserById(user.uid, idToken))
         dispatch(getUserStatusById(user.uid, idToken))
         dispatch(getUserThemeById(user.uid, idToken,history))
         // NotificationManager.success('User logged in successfully!');
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         console.log("%c Error Occured while fetching user profile data ","color:red", errorMsg);
     })
 }
 
 /**
  * Redux Action To Sigin User With Firebase
  */
 export const signinUserInFirebase = (user, history, token) => (dispatch) => {
 console.log(" Sending request to signin firebase with custom token........");
   // firebase.auth()
      signInWithCustomToken(auth, token)
         .then((response) => {
            dispatch({ type: LOGIN_USER });
            console.log("%c Successfully signed in firebase with custom token","color:green");
            console.log(" Fetching firebase user idToken.......");
            response.user.getIdToken().then(function (result) {
               user.uid=response.user.uid;
               localStorage.setItem("signedInUseruid", user.uid);
               localStorage.setItem("signedInIdToken", result);
               console.log("%c fetched firebase user idToken","color:green");
               dispatch(getPPolicies(user, history, result))
               // dispatch(getUserProfile(user, history, result));
               // dispatch(setUserTimezone(response.user.uid, result))
         }).catch(function (error) {
            const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
            console.log("%c Error Occured while fetching firebase idToken ","color:red", errorMsg);
            history.push("/logout")
            //console.log('google user error-->',error)
         });
      })
      .catch((error) => {
         dispatch({ type: LOGIN_USER_FAILURE });
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         console.log("%c Error Occured while signin firebase with custom token ","color:red", errorMsg);
         NotificationManager.error(errorMsg);
         history.push("/logout")
      });
 }

 export const getAuthorisedModule = (userId, orgId) => (dispatch) => {
   dispatch({type: GET_ALLOWED_MODULE})
   const moduleRef = ref(database,`ACL/${userId}/${orgId}/Modules`)
   try {
      onValue(moduleRef,(snapshot) => {
         const allowedModules = snapshot.val();
         dispatch({type: GET_ALLOWED_MODULE_SUCCESS, payload: allowedModules})
      }, {
         onlyOnce: true
       })
   } catch(error) {
      dispatch({type: HIDE_FIREBASE_AUTH_LOADING})
      console.log(' google user error-->',error)
   }
}

export const isSuperAdmin = (userId) => (dispatch) => {
   dispatch({type: GET_SUPER_ADMIN_DATA})
   const superAdminRef =  ref(database,`SuperAdmin/${userId}/`)
   try {
      onValue(superAdminRef, (snapshot) => {
         const superAdminData = snapshot.val();
         dispatch({type: GET_SUPER_ADMIN_DATA_SUCCESS, payload: superAdminData})
      },{
         onlyOnce: true
      })
   } catch(error) {
      dispatch({type: HIDE_FIREBASE_AUTH_LOADING})
      console.log('google user error-->',error)
   }
}
export const isWorkspaceAdminStatus = (userId) => (dispatch) => {
   dispatch({type: GET_WORKSPACE_ADMIN_DATA});
   const orgId = localStorage.getItem("orgId");
   //console.log(" fetching workspace admin status -->")
   const workSpaceRef = ref(database,`Organizations/${orgId}/Zoho/WorkspaceAdmins/list/${userId}`)
   try {
      onValue(workSpaceRef, (snapshot) => {
         const isWorkspaceAdmin = snapshot.val();
         console.log("workspaceAdmin --> ", isWorkspaceAdmin)
         dispatch({type: GET_WORKSPACE_ADMIN_DATA_SUCCESS, payload: isWorkspaceAdmin})
      },{
         onlyOnce: true
      })
   } catch (error) {
      dispatch({type: HIDE_FIREBASE_AUTH_LOADING})
      console.log(' error occured while fetching workspace admin status -->',error)
   }
}
export const isGlobalAdmin = (userId) => (dispatch) => {
   dispatch({type: GET_GLOBAL_ADMIN_DATA})
   const gloablAdminRef = ref(database,`GlobalUsers/${userId}/`)
   try {
      onValue(gloablAdminRef,(snapshot) => {
         const globalAdminData = snapshot.val();
         dispatch({type: GET_GLOBAL_ADMIN_DATA_SUCCESS, payload: globalAdminData})
      },{
         onlyOnce: true
      })
   } catch (error) {
      dispatch({type: HIDE_FIREBASE_AUTH_LOADING})
      console.log('google user error-->',error)
   }
}
export const setFormStatusData=(data)=>(dispatch)=>{
   dispatch({type:SET_FORM_STATUS_DATA,payload:data});
}

export const getClientStatusDataById=(uid,idToken)=>(dispatch)=>{
   const orgId = localStorage.getItem("selectedOrgId") || localStorage.getItem("orgId")
   dispatch({type:GET_STATUS_LOADING})
   let url = `/users/${uid}/forms-responses/multiple-user-status?organization=${orgId}` ///users/{userId}/forms-responses/multiple-user-status
   axiosClient.get(url, {
      headers: {
         'Authorization': `Bearer ${idToken}`
      }
      }).then((response) => {
         dispatch({type:GET_CLIENT_STATUS_DATA,payload:response.data})
         let statusList=[];
         let sections=[]
         Object.keys(response.data.sections).map((obj, key) => {
           sections.push(obj)
           statusList.push(response.data.sections[obj])
        })
        dispatch(setFormStatusData(statusList))
      // dispatch(updateStatus(uid,response.data))
     }).catch(async(error) => {
      // error handling
      dispatch({type:HIDE_STATUS_LOADING})
      console.log('google user error-->',error)
  })
}
 export const getUserStatusById=(uid,idToken)=>(dispatch)=>{
   const orgId = localStorage.getItem("selectedOrgId") || localStorage.getItem("orgId")
   // let userId= localStorage.getItem('uid')
    //dispatch({type:REMOVE_STATUS})
    console.log(" Fetching user status data by userId......");
    dispatch({type:GET_STATUS_LOADING})
   let url = `/users/${uid}/forms-responses/multiple-user-status?organization=${orgId}` ///users/{userId}/forms-responses/multiple-user-status
    axiosClient.get(url, {
       headers: {
          'Authorization': `Bearer ${idToken}`
       }
       }).then((response) => {
          dispatch({type:GET_STATUS,payload:response.data})
          let statusList=[];
          let sections=[]
          Object.keys(response.data.sections).map((obj, key) => {
            sections.push(obj)
            statusList.push(response.data.sections[obj])
         });
         console.log("%c Fetched status data for userId:"+uid,"color:green");
         dispatch(setFormStatusData(statusList))
       // dispatch(updateStatus(uid,response.data))
      }).catch(async(error) => {
         // error handling
         dispatch({type:HIDE_STATUS_LOADING});
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         console.log("%c Error Occured while fetching user status data ","color:red", errorMsg);
        // console.log('google user error-->',error)
     })
 }
 export const getUserStatusByIdFromConfigurator=(uid,idToken)=>(dispatch)=>{
   let userId=localStorage.getItem('uid')
   let orgId =  localStorage.getItem('orgId');
  // dispatch({type:REMOVE_STATUS})
  dispatch({type:GET_STATUS_LOADING})
  let url = `/users/${userId}/forms-responses/multiple-user-status?organization=${orgId}` ///users/{userId}/forms-responses/multiple-user-status
   axiosClient.get(url, {
      headers: {
         'Authorization': `Bearer ${idToken}` 
      }
      }).then((response) => {
         dispatch({type:GET_STATUS,payload:response.data})
         let statusList=[]; 
         let sections=[]
         Object.keys(response.data.sections).map((obj, key) => {
           sections.push(obj)
           statusList.push(response.data.sections[obj])
        })
        dispatch(setFormStatusData(statusList))
       // dispatch(updateStatusFromConfigurator(uid,response.data))
     }).catch(async(error) => {
      // error handling
      dispatch({type:HIDE_STATUS_LOADING})
      console.log('google user error-->',error)
  })
}

export const updateStatusFromConfigurator=(uid,data)=>(dispatch)=>{
   let idToken=localStorage.getItem('idToken')
   const orgId = localStorage.getItem("orgId")
  let url=`/users/${uid}/forms-responses/multiple-user-status?organization=${orgId}`;  ///users/{userId}/forms-responses/multiple-user-status
  axiosClient.post(url,data, {
     headers: {
        'Authorization': `Bearer ${idToken}`
     }
     }).then((response) => {
        let statusData=[]
        let list=[]
        Object.keys(response.data).map((obj, key) => {
           //statusList.push(response.data[obj])
           statusData.push(response.data[obj])
        })
        Object.keys(statusData[1]).map((obj, key) => {
           //statusList.push(response.data[obj])
           list.push(statusData[1][obj])
           
        })
        if(list[0]){
           data.value=list[0]?list[0].status:null
           data.color=list[0]?list[0].color:'white'
           data.score=list[0]?list[0].score:0
        }
        if(list[1]){
           data.value2=list[1]?list[1].status:null
           data.color2=list[1]?list[1].color:'white'
           data.score2=list[1]?list[1].score:0
        }
        if(list[2]){
           data.value3=list[2]?list[2].status:null
           data.color3=list[2]?list[2].color:'white'
           data.score3=list[2]?list[2].score:0
        }
        if(list[3]){
           data.value4=list[3]?list[3].status:null
           data.color4=list[3]?list[3].color:'white'
           data.score4=list[3]?list[3].score:0
        }
        dispatch({type:UPDATE_USER_STATUS,payload:data})
    }).catch(async(error) => {
      // error handling
      console.log('google user error-->',error)
   })
}

 export const getStatusColor=(status,status2,uid,idToken)=>(dispatch)=>{
   let url = `/users/${uid}/user-status-set` //users/{userId}/user-status-set
    axiosClient.get(url, {
       headers: {
          'Authorization': `Bearer ${idToken}`
       }
       }).then((response) => {         
         let userStatus=response.data.optionsDetailed.find((list)=>list.name===status.value)
         let userStatus2=response.data.optionsDetailed.find((list)=>list.name===status2.value)
         let data={value:userStatus.name,color:userStatus.color,score:userStatus.score,value2:userStatus2?userStatus2.name:status2.value,color2:userStatus2?userStatus2.color:'#000000',score2:userStatus2?userStatus2.score:1}
         dispatch({type:UPDATE_USER_STATUS,payload:data})
         //dispatch(setStatusColor('Red'))
      }).catch(async(error) => {
         // error handling
         console.log('google user error-->',error)
      })
 }
 export const updateStatus=(uid,data)=>(dispatch)=>{
   console.log(" Updating current status.....");
   let idToken=localStorage.getItem('idToken')
   const orgId = localStorage.getItem("selectedOrgId") || localStorage.getItem("orgId")
   let url=`/users/${uid}/forms-responses/multiple-user-status?organization=${orgId}`;  ///users/{userId}/forms-responses/multiple-user-status
   axiosClient.post(url,data, {
      headers: {
         'Authorization': `Bearer ${idToken}`
      }
      }).then((response) => {
         // console.log("data from getStatusById=>",data,"update data=>",response.data)
         let userStatus=[]
         let list=[]
         Object.keys(response.data).map((obj, key) => {
            //statusList.push(response.data[obj])
            userStatus.push(response.data[obj])
         })
         Object.keys(userStatus[1]).map((obj, key) => {
            //statusList.push(response.data[obj])
            list.push(userStatus[1][obj])
            
         })
         let statusData=[]
         list.map((status,key) => {
             let data={}
             data.value=list[key]?list[key].status:null
             data.color=list[key]?list[key].color:'white'
             data.score=list[key]?list[key].score:0
             data.direction=list[key] && list[key].direction ? list[key].direction : null
             statusData.push(data)
         })
         if(list[0]){
            data.value=list[0]?list[0].status:null
            data.color=list[0]?list[0].color:'white'
            data.score=list[0]?list[0].score:0
         }
         if(list[1]){
            data.value2=list[1]?list[1].status:null
            data.color2=list[1]?list[1].color:'white'
            data.score2=list[1]?list[1].score:0
         }
         if(list[2]){
            data.value3=list[2]?list[2].status:null
            data.color3=list[2]?list[2].color:'white'
            data.score3=list[2]?list[2].score:0
         }
         if(list[3]){
            data.value4=list[3]?list[3].status:null
            data.color4=list[3]?list[3].color:'white'
            data.score4=list[3]?list[3].score:0
         }
         console.log("%c Updated current status for userId:"+uid,"color:green");
         console.table(statusData);
         dispatch({type:UPDATE_USER_STATUS,payload:statusData})
     }).catch(async(error) => {
      // error handling
      const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
      console.log("%c Error Occured while updating user current status","color:red", errorMsg);
      //console.log('google user error-->',error)
   })
 }
 export const setStatusColor=(color)=>(dispatch)=>{
    dispatch({type:SET_STATUS_COLOR,payload:color})
 }
 /**
  * Redux Action To Sigin User With Firebase
  */
 export const signinUserInFirebaseToken = (token, history) => (dispatch) => {
    dispatch({ type: LOGIN_USER });
   //  firebase.auth()
      signInWithCustomToken(auth, token)
       .then((response) => {
          localStorage.setItem("user_email", response.user.email);
         //  localStorage.setItem("user_password", response.user.password);
          localStorage.setItem("uid", response.user.uid);
          localStorage.setItem("auth_uid", response.user.uid);
          response.user.getIdToken().then((result) => {
            localStorage.setItem("idToken", result);
            dispatch({ type: LOGIN_USER_SUCCESS, payload: response.user});
            dispatch(getUserById(response.user.uid, result))
            dispatch(getUserStatusById(response.user.uid,token))
            //  history.push('/newactivity');
             NotificationManager.success('User logged in successfully!');
          }).catch((error) => {
             console.log('google user error-->',error)
             NotificationManager.error(error.message);
          });
       })
       .catch((error) => {
          dispatch({ type: LOGIN_USER_FAILURE });
          console.error(" error occured while signing with custom data", error);
          NotificationManager.error(error.message);
       });
 }
 
 /**
  * Redux Action To Signout User From  Firebase
  */
 export const logoutUserFromFirebase = (history, logout, redirectUrl) => (dispatch) => {
   const uid = localStorage.getItem('auth_uid')
   const idToken = localStorage.getItem('idToken');
   const fcmToken = localStorage.getItem("fcmToken");
   const permissionStatus = Notification ?  Notification.permission : "";
   const url = `/users/${uid}/app-data`;
   const payload = {deviceId : fcmToken}
   //unregistering service workers
   console.log("deleted device details for notification", permissionStatus, fcmToken);
   removeServiceWorkers()
   if(permissionStatus == "granted" && fcmToken) {
      axiosClient.delete(url, {headers: {
         'Authorization': `Bearer ${idToken}`
         },
         data: payload
      })
      .then((response) => {
         console.log(" deleted device details for notification -->", response);
         dispatch(signOutAndRemoveStorage(logout, redirectUrl))
      }).catch((error) => {
         console.error(" error occured while deleting app data ", error);
         dispatch(signOutAndRemoveStorage(logout, redirectUrl))
      })
   } else {
      dispatch(signOutAndRemoveStorage(logout, redirectUrl))
   }
}

export const signOutAndRemoveStorage = (logout, redirectUrl) => (dispatch) => {
   signOut(auth).then(() => {
      dispatch(removeLocalStorageWithDefaultRedux(logout, redirectUrl))
   }).catch((error) => {
      console.error(" error occured while signing out from firebase", error);
      NotificationManager.error(error.message);
   })
}
 /**
  * Redux Action To Signup User In Firebase
  */
 export const signupUserInFirebase = (user, history) => (dispatch) => {
    dispatch({ type: SIGNUP_USER });
    createUserWithEmailAndPassword(auth, user.email, user.password)
       .then((success) => {
          localStorage.setItem("user_id", "user-id");
          dispatch({ type: SIGNUP_USER_SUCCESS, payload: localStorage.getItem('user_id') });
          history.push('/');
          NotificationManager.success('Account Created Successfully!');
       })
       .catch((error) => {
          dispatch({ type: SIGNUP_USER_FAILURE });
          NotificationManager.error(error.message);
       })
 }
 
 /**
  * Redux Action To Signin User In Firebase With Facebook
  */
 export const signinUserWithFacebook = (history) => (dispatch) => {
    dispatch({ type: LOGIN_USER });
   //  const provider = new firebase.auth.FacebookAuthProvider();
   //  firebase.auth().signInWithPopup(provider).then(function (result) {
   //     localStorage.setItem("user_id", "user-id");
   //     dispatch({ type: LOGIN_USER_SUCCESS, payload: localStorage.getItem('user_id') });
   //     history.push('/');
   //     NotificationManager.success(`Hi ${result.user.displayName}!`);
   //  }).catch(function (error) {
   //     dispatch({ type: LOGIN_USER_FAILURE });
   //     NotificationManager.error(error.message);
   //  });
 }
 
 /**
  * Redux Action To Signin User In Firebase With Google
  */
 export const signinUserWithGoogle = (history) => (dispatch) => {
    dispatch({ type: LOGIN_USER });
   //  const provider = new firebase.auth.GoogleAuthProvider();
   //  firebase.auth().signInWithPopup(provider).then(function (result) {
   //     localStorage.setItem("user_id", "user-id");
   //     dispatch({ type: LOGIN_USER_SUCCESS, payload: localStorage.getItem('user_id') });
   //     history.push('/');
   //     NotificationManager.success(`Hi ${result.user.displayName}!`);
   //  }).catch(function (error) {
   //     dispatch({ type: LOGIN_USER_FAILURE });
   //     NotificationManager.error(error.message);
   //  });
 }
 
 /**
  * Redux Action To Signin User In Firebase With Github
  */
 export const signinUserWithGithub = (history) => (dispatch) => {
    dispatch({ type: LOGIN_USER });
   //  const provider = new firebase.auth.GithubAuthProvider();
   //  firebase.auth().signInWithPopup(provider).then(function (result) {
   //     localStorage.setItem("user_id", "user-id");
   //     dispatch({ type: LOGIN_USER_SUCCESS, payload: localStorage.getItem('user_id') });
   //     history.push('/');
   //     NotificationManager.success(`Hi ${result.user.displayName}!`);
   //  }).catch(function (error) {
   //     dispatch({ type: LOGIN_USER_FAILURE });
   //     NotificationManager.error(error.message);
   //  });
 }
 
 /**
  * Redux Action To Signin User In Firebase With Twitter
  */
 export const signinUserWithTwitter = (history) => (dispatch) => {
    dispatch({ type: LOGIN_USER });
   //  const provider = new firebase.auth.TwitterAuthProvider();
   //  firebase.auth().signInWithPopup(provider).then(function (result) {
   //     localStorage.setItem("user_id", "user-id");
   //     dispatch({ type: LOGIN_USER_SUCCESS, payload: localStorage.getItem('user_id') });
   //     history.push('/');
   //     NotificationManager.success('User logged in successfully!');
   //  }).catch(function (error) {
   //     dispatch({ type: LOGIN_USER_FAILURE });
   //     NotificationManager.error(error.message);
   //  });
 }
 
 export const showAuthLoading = () => ({
    type: SHOW_FIREBASE_AUTH_LOADING
 })
 
 export const hideAuthLoading = () => ({
    type: HIDE_FIREBASE_AUTH_LOADING
 })
 
 export const getPrivacyPlocy = (params, history) => (dispatch) => {
    const org = params.organization ? params.organization : 'SbqnT7qt9kXY4robcR7u'
    console.log(" Fetching organization settings.....");
    let url = `/organizations/${org}/settings`
    dispatch({ type: GET_ORGANIZATION_PRIVACY_POLICY });
    axiosClient.get(url)
       .then((response) => {
          if (response.status === 200) {
               dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: response.data});
               console.groupEnd();
             if (params.organization && params.region && params.email){
                 console.log("Redirecting on auth0login screen... ");
                 window.location.href = `/auth0login?organization=${params.organization}&region=${params.region}&email=${params.email}`
               } else if (params.organization && params.region && params.token){
                 console.log("Redirecting on auth0login screen... ");
                 window.location.href = `/auth0login?organization=${params.organization}&region=${params.region}&token=${params.token}`
               }
             
          } else {
             dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: response.data});
          }
          console.log("%c Fetched organization settings","color:green");
       }).catch(async(error) => {
         // error handling
         const stattusData  = error && error.response ? error.response.data : error
         dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: stattusData});
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         console.log("%cError occured while fetching privacy policy details","color:red", errorMsg);
        // console.log("error occured ", error);
      })
 }
 export const getPrivacyPlocyFromToken = (params, history) => (dispatch) => {
   const org = params.organization ? params.organization : 'SbqnT7qt9kXY4robcR7u'
   console.log(" Fetching organization settings.....");
   let url = `/organizations/${org}/settings`
   dispatch({ type: GET_ORGANIZATION_PRIVACY_POLICY });
   axiosClient.get(url)
      .then((response) => {
         if (response.status === 200) {
            // console.log(params.organization, response.data)
            const enterpriseConnection = checkEnterpriseConnection(response.data, params.organization)
            // console.log(params.organization, response.data, enterpriseConnection)
            dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: response.data});
               if(!enterpriseConnection){
                  history.push(`/signuplifesherpa`);
               } else if (params.organization && params.region && params.token){
                  window.location.href = `/auth0login?organization=${params.organization}&region=${params.region}&token=${params.token}`
               }  
            
            
         } else {
            dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: response.data});
         }
         console.log("%c Fetched organization settings","color:green");
      })
      .catch(error => {
         // error handling
         const stattusData  = error && error.response ? error.response.data : error
         dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: stattusData});
         console.log("error occured ", error);
      })
}
 export const sendVerificationLink = (userdata, history) => (dispatch) => {
    let url = '/users/resend-email-verification'
    dispatch({ type: RESEND_VERIFICATION_LINK });
    axiosClient.post(url,userdata)
       .then((response) => {
          if (response.status === 200) {
             dispatch({ type: RESEND_VERIFICATION_LINK_SUCCESS, payload: {status: response.data.status, email: userdata.email}});
             history.push('/resendverificationlinksuccess');
          } else {
             dispatch({ type: RESEND_VERIFICATION_LINK_SUCCESS, payload: {status: response.data, email: userdata.email }});
          }
       })
       .catch(error => {
          // error handling
          const stattusData  = error && error.response ? error.response.data : error
          dispatch({ type: RESEND_VERIFICATION_LINK_SUCCESS, payload: {status: stattusData, email: userdata.email }});
          console.log("error occured ", error);
       })
 }
 
 //verify  email
 export const verifyingEmail = (userdata, history) => (dispatch) => {
    let url = '/users/verify-email'
    dispatch({ type: VERIFY_USER_ACCOUNT_VIA_MAIL });
    axiosClient.post(url,userdata)
       .then((response) => {
          if (response.data.status === 'success') {
             dispatch({ type: VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL, payload: {'status': response.data.status, 'code': userdata.code}});
             history.push('/emailverificationsuccess');
          } else {
             dispatch({ type: VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL, payload: {'status': response.data.status, 'code': userdata.code}});
             history.push('/emailverificationfailure');
          }
       })
       .catch(error => {
          // error handling
          dispatch({ type: VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL, payload: {'status': "failed", 'code': userdata.code}});
          history.push('/emailverificationfailure');
          console.log("error occured ", error);
       })
 }
 
 //Reset password
 export const resetPassword = (userdata, history) => (dispatch) => {
    let url = '/users/confirm-code'
    dispatch({ type: RESET_USER_PASSWORD });
    axiosClient.post(url, userdata)
       .then((response) => {
          if (response.status === 200) {
             
             dispatch({ type: RESET_USER__PASSWORD_SUCCESS, payload: {'status': 'success', 'resetPassword': userdata.newPassword, data: response.data}});
             history.push('/resetpasswordsuccess');
          } else {
             
             dispatch({ type: RESET_USER__PASSWORD_SUCCESS, payload: {'status': 'failed', 'resetPassword': userdata.newPassword, data: response.data}});
          }
       })
       .catch(error => {
          // error handling
          
          dispatch({ type: RESET_USER__PASSWORD_SUCCESS, payload: {'status': "failed", 'resetPassword': userdata.newPassword, data: error}});
          console.log("error occured ", error);
       })
 }
 
 
 //register user
 export const createUser = (userData, history) => async(dispatch) => {
    let url = 'users/register'
    dispatch({ type: POST_LIFE_SHERPA_CREATE_USER });
    lifesherpaAuthBaseAPI.post(url, userData)
       .then((response) => {
          let userPayload = {'user': response.data, 'userData': userData}
          if (response.status === 200) {
             dispatch({ type: POST_LIFE_SHERPA_SUCCESS_CREATE_USER, payload: userPayload});
             history.push('/welcome'); 
          } else {
             dispatch({ type: POST_LIFE_SHERPA_SUCCESS_CREATE_USER, payload: userPayload});
          }
       })
       .catch(error => {
          const errorData = error.response ? { message: error.response.data.message || error.response.data.messsage} : { message: "Something went wrong, try again!"};
          // const errorData = { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
          dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
          dispatch({ type: POST_LIFE_SHERPA_FAILED_CREATE_USER, payload: errorData});
          console.log("error occured ", error);
       })
 }
 export const clearCreateUserFailedError = () => (dispatch) => {
   dispatch({type: HIDE_CREATE_USER_FAILED_DATA})
}

 //Validate the invite code
 export const validateInviteCode = (inviteCode, history) => (dispatch) => {
    let url = `users/invite-code/${inviteCode}`
    dispatch({ type: GET_VALIDATE_INVITE_CODE });
    axiosClient.get(url)
       .then((response) => {
          let tokenRepose = {'inviteCode': inviteCode, 'status': response.data.status}
          if (response.data.status === 'success') {
             dispatch({ type: GET_SUCCESS_VALIDATE_CODE, payload: tokenRepose}); 
             //history.push('/signuplifesherpa');
          } else {
             dispatch({ type: GET_SUCCESS_VALIDATE_CODE, payload: tokenRepose});
          }
       })
       .catch(error => {
          // error handling
          let tokenRepose = {'inviteCode': inviteCode, 'status': 'failed'}
          dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
          dispatch({ type: GET_SUCCESS_VALIDATE_CODE, payload: tokenRepose});
          console.log("error occured ", error);
       })
 }
 export const clearValidateInviteCodeError = () => (dispatch) => {
   dispatch({type: CLEAR_GET_VALIDATE_INVITE_CODE_RESPONSE})
}
 //register user
 export const authUser = (userData, history) => async(dispatch) => {
    let url = '/users/authenticate'
    dispatch({ type: POST_LIFE_SHERPA_LOGIN });
    lifesherpaAuthBaseAPI.post(url, userData)
       .then((response) => {
          let userPayload = {'user': response.data, 'userData': userData}
          if (response.status === 200) {
             localStorage.setItem('refreshToken', response.data.firebase_token)
             history.push('/loginsuccess');
             dispatch({ type: POST_LIFE_SHERPA_LOGIN_SUCCESS, payload: userPayload});
          } else {
             dispatch({ type: POST_LIFE_SHERPA_LOGIN_SUCCESS, payload: userPayload});
          }
       })
       .catch(error => {
          // error handling
          let userPayload = {'user': error, 'userData': userData}
          dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
          dispatch({ type: POST_LIFE_SHERPA_LOGIN_SUCCESS, payload: userPayload});
          console.log("error occured ", error);
          NotificationManager.error(error.message);
       })
 }
 
 //get custom token user
 export const getUserFirebaseToken = (userData, history, auth0logout) => async(dispatch) => {
    const url = "/v3/users/custom-token" //'/auth/v3/getCustomToken'
    const token = userData.token
    const orgId = localStorage.getItem("orgId")
    const user = userData.profile;
    const {region} = userData
    const timezoneOffset = moment().format('Z')
   //  const data = {orgId, user, token, region, authExp: Date.now() + 60 * 60 * 1000 }
   let data = {orgId, user, token, region, timezoneOffset}
   if(userData.loginCode) {
      data.loginCode = userData.loginCode;
   }
   console.log(" Fetching firebase custom token.....");  
    dispatch({ type: GET_USER_FIREBASE_TOKEN });
    axiosAuthClient.post(url, data, {
       headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
          }
    })
    .then((response) => {
       if (response.status === 200) {
          dispatch({ type: GET_SUCCESS_USER_FIREBASE_TOKEN, payload: response.data.token});
          console.log("%c Firebase custom token fetched","color:green");
          dispatch(signinUserInFirebase({email:userData.profile.email,password: 'Abcd1234',emailValid: true,passwordValid: true,},history,response.data.token))
          // history.push('/welcome');
       } else {
          dispatch({ type: GET_SUCCESS_USER_FIREBASE_TOKEN, payload: response.data});
       }
    }).catch(async(error) => {
         // error handling
         const errorData = error.response ? { message: error.response.data.message || error.response.data.messsage} : { message: "Something went wrong, try again!"};
         const payloadData = error.response ? error.response.data : error
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         const errorCode = error.response && error.response.data ? error.response.data.errorCode : null;
         console.log("%c Error Occured while fetching feirebase custom token ","color:red", errorMsg);
         const connection = localStorage.getItem("connection")
         if (errorCode === 'E1119' && region === 'us') {
            window.location.replace(`${config.registraionappurl}&loginMode=idp-initiated-sso&region=us&connection=${connection}`)
         } else if (errorCode === 'E1119' && region === 'austrelia') {
            window.location.replace(`${config.tokenRegistrationURL}&loginMode=idp-initiated-sso&region=austrelia&connection=${connection}`)
         } else {
            dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
            dispatch({ type: GET_SUCCESS_USER_FIREBASE_TOKEN, payload: payloadData});
            dispatch({ type: GET_FAILED_USER_FIREBASE_TOKEN, payload: errorData});
            auth0logout({returnTo: window.location.origin , federated:true})
         }
        // console.log("error occured ", error);
   })
 }

 export const removeFirebaseErrorMessage = () =>(dispatch) => {
   dispatch({ type: GET_FAILED_USER_FIREBASE_TOKEN, payload: null});
 }

 //get firebase custom token
 export const getFirebaseCustomToken = (auth0AccessToken) => {
   const url = "/v3/users/custom-token" //'/auth/v3/getCustomToken'
   const token = auth0AccessToken
   const orgId = localStorage.getItem("orgId")
   const region = localStorage.getItem("region");
   const timezoneOffset = moment().format('Z')
   // const data = {orgId, token, region, authExp: Date.now() + 60 * 60 * 1000}
   let data = {orgId, token, region, timezoneOffset}
   return axiosAuthClient.post(url, data, {
      headers: {
         'Content-Type': 'application/json',
         'Authorization' : `Bearer ${token}`
         }
   })
}

export const logoutCallback = (bool, message) => () => {
   const uid = localStorage.getItem('auth_uid')
   const idToken = localStorage.getItem('idToken');
   const fcmToken = localStorage.getItem("fcmToken");
   const permissionStatus = Notification ?  Notification.permission : "";
   const url = `/users/${uid}/app-data`;
   const payload = {deviceId : fcmToken}
   //unregistering service workers
   removeServiceWorkers()
   if(permissionStatus == "granted" && fcmToken) {
      axiosClient.delete(url, {headers: {
          'Authorization': `Bearer ${idToken}`
          },
          data: payload
      })
      .then((response) => {
         console.log(" deleted device details for notification -->", response);
         signOut(auth)
         .then(() => {
            removeLocalStorage()
         })
         .catch((error) => {
            NotificationManager.error(error.message);
            window.location.href = window.location.origin
         })
      }).catch((error) => {
          console.error(" error occured while deleting app data ", error);
          removeLocalStorage()
      })
   } else {
      signOut(auth)
      .then(() => {
         removeLocalStorage()()
      })
      .catch((error) => {
         NotificationManager.error(error.message);
         window.location.href = window.location.origin
      })
   }
}
export const logoutCallbackForAuth0Expired = () =>  {
   const uid = localStorage.getItem('auth_uid')
   const idToken = localStorage.getItem('idToken');
   const fcmToken = localStorage.getItem("fcmToken");
   const permissionStatus = Notification ?  Notification.permission : "";
   const url = `/users/${uid}/app-data`;
   const payload = {deviceId : fcmToken}
   //unregistering service workers
   removeServiceWorkers()
   if(permissionStatus == "granted" && fcmToken) {
      axiosClient.delete(url, {headers: {
          'Authorization': `Bearer ${idToken}`
          },
          data: payload
      })
      .then((response) => {
         console.log(" deleted device details for notification -->", response);
         signOut(auth)
         .then(() => {
            removeLocalStorage()
         })
         .catch((error) => {
            NotificationManager.error(error.message);
            window.location.href = window.location.origin
         })
      }).catch((error) => {
          console.error(" error occured while deleting app data ", error);
          removeLocalStorage()
      })
   } else {
      signOut(auth)
      .then(() => {
         removeLocalStorage()
      })
      .catch((error) => {
         NotificationManager.error(error.message);
         window.location.href = window.location.origin
      })
   }
}

export const clearUserDataOfAuth0 = () => (dispatch) => {
   dispatch({ type: LOGOUT_USER});
   dispatch({ type: GET_SUCCESS_USER_ORGANIZATION, payload: null});
}

 export const clearUserOrgAndRegion = () => async(dispatch) => {
      dispatch({ type: GET_SUCCESS_USER_ORGANIZATION, payload:null}); 
}

 export const getUserOrgAndRegion = (email, history) => async(dispatch) => {
    let url = '/users/search-email'
    let data = {email: email}
    dispatch({ type: GET_USER_ORGANIZATION });
    lifesherpaAuthBaseAPI.post(url, data)
       .then((response) => {
          if (response.status === 200) {
             let payloadData = response.data
             payloadData["email"] = email
             localStorage.setItem("auth0ClientConfig",JSON.stringify(response.data.auth0ClientConfig));
             localStorage.setItem("region",response.data.region);
             dispatch({ type: GET_SUCCESS_USER_ORGANIZATION, payload: payloadData});
            //  console.log("search-email data --> ",response.data)
             if(response.data.organization){
                localStorage.setItem("organization",response.data.organization);
                localStorage.setItem("orgId",response.data.organization);
                localStorage.setItem("selectedOrgId", response.data.organization);
                console.log("%cUserName/email is authenticated ","color:green");
                dispatch(getPrivacyPlocy({organization: response.data.organization, region: response.data.region, email},history))
               }
          } else {
             dispatch({ type: GET_SUCCESS_USER_ORGANIZATION, payload: response.data}); 
          }
       })
       .catch(error => {
          // error handling
          const payLoadData = error.response && error.response.data ?  error.response.data : error
          dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
          dispatch({ type: GET_SUCCESS_USER_ORGANIZATION, payload: payLoadData});
          const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
          console.log("%cInvalid email/username.  Please provide a valid email/username","color:red", errorMsg);
       })
 }


 export const getUserChangePassword = (email) => async(dispatch) => {
   let url = '/users/search-email'
   let data = {email: email}
   lifesherpaAuthBaseAPI.post(url, data)
      .then((response) => {
         const auth0ClientConfig = response.data.auth0ClientConfig;
         if(auth0ClientConfig) {
           dispatch({ type: GET_ALLOW_CHANGE_PASSWORD, payload: auth0ClientConfig.allowChangePassword});
         }
         if(response.data.organization){
            localStorage.setItem("organization",response.data.organization);
            localStorage.setItem("orgId",response.data.organization);
            localStorage.setItem("selectedOrgId", response.data.organization);
         }
      })
      .catch(error => {
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         console.log("%cInvalid email/username.  Please provide a valid email/username","color:red", errorMsg);
      })
}

 export const setDefaultThemeForUser = (userTheme,history) => (dispatch) => { 
   console.log(" Fetching user theme .....");
   let theme = {}
   if(userTheme) {
      theme['id'] = 1;
      theme['name'] = userTheme;
   }
   else {
      theme = localStorage.getItem("theme") ? JSON.parse(localStorage.getItem("theme")) : {id: 1, name: "primary"};
   }
   console.log("%c User default theme is "+theme.name+"","color:green");
   // console.log("user default theme --> ",userTheme,theme);
   localStorage.setItem("theme",JSON.stringify(theme));
   dispatch(getLSThemeLogo(theme,history));
  // dispatch({ type: CHANGE_THEME_COLOR,payload:theme});
}
export const getLSThemeLogo = (theme, history) => (dispatch) => {
   console.log(" Fetching organization theme.....");
   let userTheme = theme.name
   let url = `/themes/settings?theme=${userTheme.charAt(0).toUpperCase()+userTheme.slice(1)}`;
   // console.log("theme url--->",url);
    let activeTheme = {id:1, name:"primary"};
    let defaultThemeClasses = {screenThemeClass : 'rct-session-wrapper-ls' ,popupThemeClass : 'activity-template-ls-default',themeLogo:null, activeTheme };
    const idToken = localStorage.getItem('idToken');
    const organization = localStorage.getItem('organization');
    if(window.location.pathname == "/launchscreen" && !idToken && !organization) {
      //   dispatch(resetReduxData());
      //   localStorage.removeItem("auth0region");
    } 
    
   dispatch({type: GET_THEME_LS_LOGO});
   console.log(" Fetching theme data for "+theme.name+".....");
   localStorage.removeItem("loginLogoImage");
   axiosClient.get(url,{
      }).then((response) => {
         if (response.status === 200 && response.data && response.data.primaryButtonNormalStateColor) {
              // console.log("theme settings data 200 --> ",response.data)
               localStorage.setItem("loginLogoImage",response.data.loginLogoImage);
               let themeObject = response.data;
               Object.keys(themeObject).map(themeAttribute=> {
                  document.body.style.setProperty(`--${themeAttribute}`,themeObject[themeAttribute])
               });
               let themeInfo = {id: 8, name:userTheme}
               let themeClasses = {screenThemeClass:"session-wrapper-theme-support", popupThemeClass:"activity-template-theme-support",themeLogo:response.data.loginLogoImage, activeTheme: themeInfo}
               dispatch({type: SET_DYNAMIC_THEME_CLASS, payload:themeClasses});
               dispatch({type: GET_THEME_LS_LOGO_SUCCESS, payload:response.data});
               console.log("%c Fetched theme data","color:green");
               let consoleLogsTable = [];
               Object.keys(themeObject).map(themeAttribute=> {
                  let obj = {key : themeAttribute};
                  if(themeObject[themeAttribute].charAt(0) != "#") {
                     obj.value =themeObject[themeAttribute];
                  } else {
                     obj.value = themeObject[themeAttribute];
                  }
                  consoleLogsTable.push(obj);
               });
               console.table(consoleLogsTable);
               if(history) {
                  console.log("Redirecting to ls-portal home screen.....");
                  console.groupEnd();
                  const widgetApp = localStorage.getItem("widgetApp");
                  if(widgetApp) {
                     history.push('/app/widget');
                  } else {
                     history.push('/app/lsdashboard');
                  }
                  NotificationManager.success('User logged in successfully!')
               } 
         } else {
            localStorage.setItem("theme",JSON.stringify(activeTheme));
            localStorage.removeItem("activeTheme");
            localStorage.removeItem("loginLogoImage");
            dispatch({type: GET_THEME_LS_LOGO_SUCCESS,payload:null})
            dispatch({type: SET_DYNAMIC_THEME_CLASS,payload:defaultThemeClasses});
            console.log("%c Error Occured while fetching theme data","color:red");
            if(history) {
               console.log(" Redirecting to ls-portal home screen.....");
               console.groupEnd();
               const widgetApp = localStorage.getItem("widgetApp");
               if(widgetApp) {
                  history.push('/app/widget');
               } else {
                  history.push('/app/lsdashboard');
               }
               NotificationManager.success('User logged in successfully!')
            } 
         }
         console.log("%c Fetched organization theme","color:green");
      }).catch((error) => {
        console.log("error occured ", error);
        localStorage.setItem("theme",JSON.stringify(activeTheme));
        localStorage.removeItem("activeTheme");
        localStorage.removeItem("loginLogoImage");
        dispatch({type: SET_DYNAMIC_THEME_CLASS,payload:defaultThemeClasses})
        dispatch({type: GET_THEME_LS_LOGO_FAILED});
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching theme data","color:red", errorMsg);
        if(history) {
         console.log(" Redirecting to ls-portal home screen.....");
         console.groupEnd();
         const widgetApp = localStorage.getItem("widgetApp");
         if(widgetApp) {
            history.push('/app/widget');
         } else {
            history.push('/app/lsdashboard');
         }
         
         NotificationManager.success('User logged in successfully!')
      } 
     })
}

export const hideThemeLoading = () => (dispatch) => {
   dispatch({type: HIDE_THEME_LOADING});
   console.log("Theme Loading hidden");
}
 //Validate the invite code
 export const getUserDetailFromToken = (inviteCode, history) => (dispatch) => {
    let url = `users/invite-code/${inviteCode}`
    dispatch({ type: GET_USER_ORGANIZATION_FROM_TOKEN });
    axiosClient.get(url)
       .then((response) => {
          let tokenRepose = {'inviteCode': inviteCode, 'status': response.data.status}
          if (response.data.status === 'success') {
            let respData =  response.data
            respData["inviteCode"] = inviteCode
            // respData["status"] = response.data.status
            localStorage.setItem("organization",response.data.organization);
            localStorage.setItem("selectedOrgId", response.data.organization);
            localStorage.setItem("orgId",response.data.organization);
            localStorage.setItem("auth0ClientConfig",JSON.stringify(response.data.auth0ClientConfig));
            localStorage.setItem("region",response.data.region);
             dispatch({ type: GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN, payload: respData}); 
            // dispatch(getPrivacyPlocy({organization: response.data.organization, region: response.data.region, token: inviteCode},history))
             dispatch(getPrivacyPlocyFromToken({organization: response.data.organization, region: response.data.region, token: inviteCode},history))
          } else {
             dispatch({ type: GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN, payload: tokenRepose});
          }
       })
       .catch(error => {
          // error handling
          let tokenRepose = {'inviteCode': inviteCode, 'status': 'failed'} 
          dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
          dispatch({ type: GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN, payload: tokenRepose});
          console.log("error occured ", error);
       })
 }
 //update Password 
export const showUpdatePasswordDialog = (bool) => (dispatch) => { 
      dispatch({ type: SHOW_PASSWORD_UPDATE_DIALOG,payload:bool});
}
export const clearUpdatePasswordError = () => (dispatch) => { 
   dispatch({ type:CLEAR_UPDATE_PASSWORD_ERROR});
}
 export const updatePassword = (oldPassword,newPassword) => (dispatch) => {
   let userId=localStorage.getItem('uid');
   let idToken=localStorage.getItem('idToken');
   let url = `/users/${userId}/update-password`;
   let body={'oldPassword':oldPassword,'newPassword':newPassword};
   if (userId) {
      console.log(" Updating user password......");
      dispatch({ type: SHOW_UPDATE_PASSWORD_LOADING});
      axiosClient.post(url,body, {headers: {
          'Authorization': `Bearer ${idToken}`
       }
      })
      .then((response) => {
         console.log("%c Password Updated Successfully","color:green");
         dispatch({ type: PASSWORD_UPDATED_SUCCESSFULLY});
          NotificationManager.success('Password Update Successfully!');
      }).catch((error) => {
         // error handling
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         console.log("%c Error Occured while updating user password","color:red",errorMsg);
         const message = error.response && error.response.data ? error.response.data.message : error.message
         NotificationManager.error(message);
         dispatch({ type: PASSWORD_UPDATE_UNSUCCESSFULL,payload:message});
      }) 
   } else {
      console.log("%c Error Occured while updating user password","color:red");
   }
}

export const realTimeStatus=(value)=>(dispatch)=>{
   let list=[]
   let data={}
  // console.log("realtime data status => ",value);
  console.log("%c Updated user status data with realtime data","color:green");
  value && Object.keys(value).map((obj, key) => {
     list.push(value[obj])
  })
  let statusData = [];
  list.map((status,key) => {
      let data={}
      data.value=list[key]?list[key].status:null
      data.color=list[key]?list[key].color:'white'
      data.score=list[key]?list[key].score:0
      data.direction=list[key] && list[key].direction ? list[key].direction : null
      statusData.push(data)
  })
   if(statusData) {
      console.table(statusData);
   } 
   dispatch({type:UPDATE_USER_STATUS,payload:statusData})
}

export const getWorkspaceDetails = (orgId) => (dispatch) => {
   dispatch({type: HIDE_ANALYTICS_MENU})
   const wkspaceRef = ref(database,`Organizations/${orgId}/Zoho/Workspace`)
   try {
      onValue(wkspaceRef,(snapshot) => {
         const data = snapshot.val();
         dispatch({ type: GET_WORKSPACE_IS_AVAILABLE, payload: data});
     },{
      onlyOnce: true
   })
   } catch (error) {
      dispatch({type: HIDE_ANALYTICS_MENU})
      console.log("error in workspace ", error);
   }
}

export const resetReduxData =()=>(dispatch)=>{
  // console.log("cache cleared..........");
   dispatch({ type: USER_LOGGED_OUT_RESET_REDUX });
   dispatch({type: GET_THEME_LS_LOGO});
}

export const clearSetting = () => (dispatch) => {
   dispatch({type: CLEAR_PUBLIC_SETTING})
}

export const configuratorOpenedInIframe = () => (dispatch) => {
   dispatch({type: OPENED_CONFIGURATOR_IN_LSPORTAL})
}
export const lmsOpenedInIframe = () => (dispatch) => {
   dispatch({type: OPENED_LMS_IN_LSPORTAL})
}
export const enableDefaultAuth0Login = () => (dispatch) => {
   dispatch({type: ENABLE_DFAULT_AUTH0_LOGIN_STATUS})
}
export const disableDefaultAuth0Login = () => (dispatch) => {
   dispatch({type: DISABLE_DFAULT_AUTH0_LOGIN_STATUS})
}
export const setUSAAuth0Enable = () => (dispatch) => {
   dispatch({type: SET_USA_AUTH0_ENABLED})
}

export const setAustraliaAuth0Enable = () => (dispatch) => {
   dispatch({type: SET_AUSTRALIA_AUTH0_ENABLED})
}

export const deleteAppDataForNotification = async() => {
   const uid = localStorage.getItem('auth_uid')
   const idToken = localStorage.getItem('idToken');
   const fcmToken = localStorage.getItem("fcmToken");
   const permissionStatus = Notification ?  Notification.permission : "";
   const url = `/users/${uid}/app-data`;
   const payload = {deviceId : fcmToken}
   if(permissionStatus == "granted") {
      axiosClient.delete(url, {headers: {
          'Authorization': `Bearer ${idToken}`
          },
          data: payload
      })
      .then((response) => {
          console.log(" deleted device details for notification -->", response);
      }).catch((error) => {
          console.error(" error occured while deleting app data ", error);
      })
   }
}

export const analyticsOpenedToNewTab = () => (dispatch) => {
   dispatch({type: ANALYTICS_LOGIN})
}

export const displayPushNotiAlertPopup = (display) => (dispatch) => {
   dispatch({type: SHOW_PUSH_NOTIFICATION_ALERT, payload: display})
}

export const hidePushNotiAlert = (display) => (dispatch) => {
   let payloadData = {
      appBuildNumber:"1316",
      appVersion: "3.14.0",
      deviceType:"Android 13.0.0",
      OS:"Android 13.0.0"
   }
   dispatch(registerDeviceForNotification(payloadData))
   dispatch({type: HIDE_PUSH_NOTIFICATION_ALERT, payload: display})
}

//Unregister firebase service worker
export const removeServiceWorkers = () => {
   if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
         registration.unregister()
      }
      console.log('Service Worker unregistered successfully');

      }).catch(function(err) {
         console.log('Service Worker registration failed: ', err);
      });
   }
}

export const removeLocalStorage = () => {
   const store = configureStore();
   store.dispatch({ type: LOGOUT_USER });
   console.log("logout successfully!")
   localStorage.removeItem(config.localStorageBucket);
   localStorage.removeItem('user_email');
   localStorage.removeItem('uid');
   localStorage.removeItem('idToken');
   localStorage.removeItem('selectedIndex');
   localStorage.removeItem("organization")
   localStorage.removeItem("pokeFormId")
   localStorage.removeItem("user_id")
   localStorage.removeItem("refreshToken")
   localStorage.removeItem("selectedGroup")
   localStorage.removeItem("orgId")
   localStorage.removeItem('auth_uid');
   localStorage.removeItem('signedInUseruid');
   localStorage.removeItem("auth0ClientConfig");
   localStorage.removeItem("region");
   localStorage.removeItem("fcmToken")
   localStorage.removeItem("connection")
   localStorage.removeItem("orgIds")
   localStorage.removeItem('selectedOrgId')
   store.dispatch({ type: USER_LOGGED_OUT_RESET_REDUX });
   store.dispatch({ type: GET_THEME_LS_LOGO });
   NotificationManager.success('User logged out successfully');
   window.auth0.logout({ returnTo: window.location.origin, federated:true })
}

export const removeLocalStorageWithDefaultRedux = (logout, redirectUrl) => (dispatch) => {
   dispatch({ type: LOGOUT_USER });
   console.log("logout successfully!", redirectUrl)
   localStorage.removeItem(config.localStorageBucket);
   localStorage.removeItem('user_email');
   localStorage.removeItem('uid');
   localStorage.removeItem('idToken');
   localStorage.removeItem('selectedIndex');
   localStorage.removeItem("organization")
   localStorage.removeItem("pokeFormId")
   localStorage.removeItem("user_id")
   localStorage.removeItem("refreshToken")
   localStorage.removeItem("selectedGroup")
   localStorage.removeItem("orgId")
   localStorage.removeItem('auth_uid');
   localStorage.removeItem('signedInUseruid');
   localStorage.removeItem("auth0ClientConfig");
   localStorage.removeItem("region");
   localStorage.removeItem("fcmToken")
   localStorage.removeItem("connection")
   localStorage.removeItem("orgIds")
   localStorage.removeItem('selectedOrgId')
   NotificationManager.success('User logged out successfully');
   dispatch(resetReduxData());
   logout({ returnTo: redirectUrl ? redirectUrl : window.location.origin , federated:true });
}

//remove local storage data
export const eraseLocalStorage = () => (dispatch) => {
   dispatch({ type: LOGOUT_USER });
   dispatch(resetWidgetData())
   localStorage.clear()
   console.log("local storage erased successfully!")
}

//get user by id
export const getUserInfo = (userId) => (dispatch) => {
   dispatch({type: SHOW_NOTIFICATION_LOADING});
   const promise = new Promise(function(resolve, reject) {
      const idToken = localStorage.getItem("idToken")
      let url = `/users/${userId}`
      axiosClient.get(url, {
         headers: {
            'Authorization': `Bearer ${idToken}`
         }
         }).then((response) => {
            dispatch({type: SAVE_USER_HAVING_NO_GROUP, payload: response.data});
            dispatch({type: HIDE_NOTIFICATION_LOADING});
            const result = response.data || {};
            return resolve(result);
          }).catch((error) => {
            // error handling
            console.log("error occured ", error);
            dispatch({type: HIDE_NOTIFICATION_LOADING});
            return reject(error)
        })
   }).catch(error => {
      console.log("failed to get user info: ", error);
   })
   return promise
}

// set default data for okta users
export const addOrgPublicSetting = (data) => (dispatch) => {
   dispatch({type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: data})
}

export const addOrgDefaultData =(defaultData) => (dispatch) => {
   dispatch({ type: GET_SUCCESS_USER_ORGANIZATION, payload: defaultData })
}

export const getPPolicies = (user, history, idToken) => (dispatch) => {
   const orgId = localStorage.getItem("selectedOrgId")
   const endpoint = `/users/${user.uid}/privacy/acceptance`
   dispatch({type: FETCH_PRIVACY_POLICIES})
   axiosClient.get(endpoint, {
      headers: {
         'Authorization': `Bearer ${idToken}`
      }
   }).then((response) => {
         if (response.data.status) {
            localStorage.setItem("uid", user.uid);
            localStorage.setItem("auth_uid", user.uid);
            localStorage.setItem("idToken", idToken);
            localStorage.removeItem("signedInIdToken");
            dispatch(getUserProfile(user, history, idToken));
            dispatch(setUserTimezone(user.uid, idToken))
            dispatch({type: FETCH_PRIVACY_POLICIES_SUCCESS, payload: response.data});
         } else {
            dispatch({type: FETCH_PRIVACY_POLICIES_SUCCESS, payload: response.data});
            history.push("/privacy-policy")
         }
      }).catch((error) => {
         // error handling
         console.log("error occured ", error);
         let paylodData = error && error.response ? error.response.data : null
         if(paylodData) {
            paylodData.version = paylodData.latestVersion
            dispatch({type: FETCH_PRIVACY_POLICIES_SUCCESS, payload: paylodData});
         }
         dispatch({type: HIDE_FIREBASE_AUTH_LOADING});
         history.push("/privacy-policy")
      })
}

export const savedPPolicies = (user, idToken, version, history) => (dispatch) => {
   const orgId = localStorage.getItem("selectedOrgId")
   const endpoint = `/users/${user.uid}/privacy/acceptance`
   const data = {"status": true, "version": version}
   dispatch({type: SAVED_PRIVACY_POLICIES})
   axiosClient.post(endpoint, data, {
      headers: {
         'Authorization': `Bearer ${idToken}`
      }
   }).then((response) => {
         localStorage.setItem("uid", user.uid);
         localStorage.setItem("auth_uid", user.uid);
         localStorage.setItem("idToken", idToken);
         localStorage.removeItem("signedInIdToken");
         dispatch({type: SAVED_PRIVACY_POLICIES_SUCCESS, payload: response.data});
         dispatch(getUserProfile(user, history, idToken));
         dispatch(setUserTimezone(user.uid, idToken))
      }).catch((error) => {
         // error handling
         console.log("error occured ", error);
         dispatch({type: SAVED_PRIVACY_POLICIES_FAILED});
      })
}